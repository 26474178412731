const enGb = {
    global: {
        active: "Active",
        autoAccept: "Auto accept",
        back: "Back",
        branch: "Branch",
        cancel: "Cancel",
        change: "Change",
        changes: "Changes",
        changeLanguage: "Change language",
        changeOrganization: "Change organization",
        chooseFile: "Choose file",
        close: "Close",
        currentOrganization: "Current organization",
        enterOrganization: "Enter your organization",
        login: "Login",
        logout: "Logout",
        ois: "Omnichannel Integration Suite",
        oisAbbr: "OIS",
        priority: "Priority",
        save: "Save",
        specifyOrganization: "Specify organization",
        configurationAlgorithm: "Configuration & algorithm",
        configuration: "Configuration",
        algorithm: "Algorithm",
        delivery: "Delivery",
        distribution: "Distribution",
        tenant: "Tenant",
        language: "Language",
        warehouse: "Warehouse",
        shippingLocation: "Shipping location",
        externalStockLocation: "External stock location",
        maxOpenRequests: "Max. open requests",
        cutOffTime: "Cut off time",
        setup: "Setup",
        monday: "Monday",
        tuesday: "Tuesday",
        wednesday: "Wednesday",
        thursday: "Thursday",
        friday: "Friday",
        saturday: "Saturday",
        sunday: "Sunday",
        modules: "Modules",
        noFileSelected: "No file selected",
        toDashboard: "To dashboard",
        toLogin: "To login",
        search: "Search",
        downloadCsvExample: "Download example (.csv)",
        import: "Import",
        importedData: (count = 0) => `${count} ${count === 1 ? "row" : "rows"} imported`,
        noChanges: "There are currently no changes",
        empty: "empty",
        undoChanges: "Undo all changes",
        or: "or",
        navigateToType: "Navigate to type",
        yes: "Yes",
        no: "No",
        moreInformation: "More information",
        noResultsFound: "No results found",
        articleSearchInfo: "Search for one of the following attributes: id, articleNumber, name, posDescription, or vendorArticleNumber",
        copied: "Copied!",
        copyToClipboard: "Copy to clipboard",
        modifiedOn: "Modified on",
        createdOn: "Created on",
        stockLocationType: "Stock location type",
        shippingLocationInternal: "Ship from warehouse location",
        backToOverview: "Back to overview",
        general: "General"
    },
    form: {
        label: {
            routesActive: "Routes active",
            maxCirculationTimeframeInDays: "Maximum circulation time in days",
            excludeWarehouseExpiredCirculations: "Exclude warehouse from maximum circulation time",
            centralizedDelivery: "centralized delivery",
            daysToExcludeExternalBranchFromCirculation: "Days to exclude external stock locations",
            hoursToExcludeBranchForPickRequestAfterReject: "Request rejection in hours for branches",
            hoursToExcludeWarehouseForPickRequestAfterReject: "Request rejection in hours for the warehouse",
            filterInputByWebshopCode: "Filter requests by webshopcode",
            pickRequestExpirationTimeInHours: "Request expiration time in hours",
            yes: "Yes",
            no: "No",
            first: "First",
            afterWhich: "after which",
            inCaseComplete: "if complete",
            everythingFrom: "everything from",
            inCountryOf: "in country of",
            branchType: "Branch type",
            openRequests: "Open requests",
            priority: "Priority",
            priorityByStockLocations: "Priority 1 at stocklocations will be chosen as the first",
            requestsPastDays: "Requests in the pas x days",
            stock: "Stock",
            most: "Most",
            least: "Least",
            selectAnOption: "Select an option",
            newRule: "New rule",
            searchByBranch: "Search by branch...",
            searchBySetting: "Search by setting..."
        },
        info: {
            routesActive: "Determines whether routes are active. The routes are managed via the stock locations.",
            maxCirculationTimeframeInDays:
                "Determines the maximum number of days the order may be in circulation before it is canceled.",
            excludeWarehouseExpiredCirculations:
                "Open circulation requests that are open for warehouse for longer than 24 hours will or will not be automatically rejected.",
            centralizedDelivery: "Determines whether there is central delivery.",
            daysToExcludeExternalBranchFromCirculation:
                "Determines the number of days (including today) that external stock locations are excluded from circulation.",
            stockLocationPriority: "Determine the priority, with 1 being the highest priority.",
            hoursToExcludeBranchForPickRequestAfterReject:
                "The amount of hours a request will not return to a branch after this branch has rejected the request before.",
            hoursToExcludeWarehouseForPickRequestAfterReject:
                "The amount of hours a request will not return to the warehouse after this warehouse has rejected the request before.",
            filterInputByWebshopCode:
                "Indicates whether the circulation input should be filtered based on the webshop code from the order.",
            pickRequestExpirationTimeInHours: "The number of hours before a request is denied on the current branch and is circulated to the next branch.",
            cutOffTimeInternalShippingAgentAvailability: "Own transport is only available for central delivery.",
            cutOffTimeRemainingShippingAgentAvailability: "This carrier is only available at shipping points."
        }
    },
    components: {
        stockLocationsImport: {
            title: "branch",
            name: "code",
            isWarehouse: "warehouse",
            isShipping: "shipping location",
            isExternal: "external stock location",
            autoAccept: "auto accept",
            maxOutstandingRequests: "max. open requests",
            priority: "priority",
            active: "active"
        }
    },
    country: {},
    language: {
        nlNL: "Nederlands",
        enGB: "English",
        frFR: "Français",
        deDE: "Deutsch"
    },
    pages: {
        page404: {
            text: "404",
            description: ""
        },
        organizationRedirect: {
            text: "Organization redirect",
            description: ""
        },
        login: {
            text: "Login",
            description: ""
        },
        root: {
            text: "Root",
            description: ""
        },
        homepage: {
            text: "Homepage",
            description: ""
        },
        dashboardRoot: {
            text: "Dashboard",
            description: ""
        },
        dashboard: {
            text: "Dashboard",
            description: "Configure OIS by clicking on one of the modules."
        },
        stockRoot: {
            text: "Stock",
            description: "Arrange stock management here."
        },
        stock: {
            text: "Stock",
            description: "Arrange stock management here."
        },
        stockManagement: {
            text: "Stock management",
            description: "Arrange stock management here."
        },
        orderCirculationRoot: {
            text: "Order circulation",
            description: "Set the order circulation settings here."
        },
        orderCirculation: {
            text: "Order circulation",
            description: "Set the order circulation settings here."
        },
        configurationAlgorithm: {
            text: "Configuration & algorithm",
            description: "Control the configuration and algorithm."
        },
        stockLocations: {
            text: "Stock locations",
            description: "Manage the stock locations."
        },
        stockLocationsImport: {
            text: "Import",
            description: "Apply changes to existing branches through a CSV import."
        },
        myAccount: {
            text: "My account",
            description: "View the details of your account."
        },
        changeTenant: {
            text: "Change Tenant",
            description: "Change the tenant for which you want to configure OIS."
        },
        changeLanguage: {
            text: "Change language",
            description: "Change the language of the OIS configuration dashboard."
        },
        settings: {
            text: "Settings",
            description: "Change the general settings of OIS."
        },
        dataViewerRoot: {
            text: "Data viewer",
            description: "View various product information and associated prices."
        },
        dataViewer: {
            text: "Data viewer",
            description: "Search within the product range, view product information and associated prices."
        },
        articlesRoot: {
            text: "Articles",
            description: "Search for products and view product information and prices."
        },
        articles: {
            text: "Articles",
            description: "Search for products and view product information and prices."
        },
        articleDetailRoot: {
            text: "General",
            description: "General."
        },
        articleDetail: {
            text: "Article details",
            description: "General."
        },
        variants: {
            text: "Variants",
            description: "Variants."
        },
        prices: {
            text: "Prices",
            description: "Prices."
        },
        properties: {
            text: "Properies",
            description: "Propterties."
        },
        monitoringRoot: {
            text: "Monitoring",
            description: "View failed and registered webhooks."
        },
        monitoring: {
            text: "Monitoring",
            description: "Gain insight into your failed and registered webhooks."
        },
        webhooksRoot: {
            text: "Webhooks",
            description: "View failed and registered webhooks."
        },
        webhooks: {
            text: "Webhooks",
            description: "View failed and registered webhooks."
        },
        webhooksFailed: {
            text: "Failed",
            description: ""
        },
        webhooksSubscriptions: {
            text: "Subscriptions",
            description: ""
        }
    },
    contextMenu: {
        retryWebhook: "Resend webhook",
        showPayload: "Show payload",
        showResponseMessage: "Show response message"
    },
    messages: {
        confirmation: {
            cancelWebhook: "Do you want to cancel this webhook?",
            retryWebook: "Would you like to resend this webhook?"
        },
        error: {
            branchCutOffTimesFetch: "Something went wrong retrieving the branch cut off time values.",
            catalogFetch: "Something went wrong retrieving the catalog values.",
            pricesFetch: "Something went wrong retrieving the prices.",
            configurationValuesFetch: "Something went wrong retrieving the configuration values.",
            distributionRulesFetch: "Something went wrong retrieving the distribution rules.",
            distributionTypesFetch: "Something went wrong retrieving the distribution types.",
            deliveryRulesFetch: "Something went wrong while retrieving the delivery rules.",
            countryTypesFetch: "Something went wrong while retrieving the country types.",
            branchesFetch: "Something went wrong while retrieving the branches.",
            branchTypesFetch: "Something went wrong while retrieving the branch types.",
            branchSettingsFetch: "Something went wrong while retrieving the branch settings.",
            shippingAgentsFetch: "Something went wrong while retrieving shipping agents.",
            algorithmSubmit: "Something went wrong while saving the order circulation aglorithm.",
            configurationSubmit: "Something went wrong while saving the order circulation configuration.",
            oisSettingGroupsFetch: "Something went wrong while retrieving the OIS setting groups.",
            oisSettingsFetch: "Something went wrong while retrieving the OIS settings by group.",
            oisSettingsSubmit: "Something went wrong while saving the OIS settings.",
            defaultError: "Something went wrong."
        },
        warning: {},
        info: {
            noAccessToPageTitle: "No access",
            noAccessToPageDescription: "You do not have access to this page. Click the button below to return to the dashboard.",
            pageNotFoundTitle: "Page not found",
            pageNotFoundDescriptionDashboard:
                "The page you're trying to reach does not exist. Klik on the button below to return to the dashboard.",
            pageNotFoundDescriptionLogin:
                "The page you're trying to reach does not exist. Klik on the button below to return to the login page.",
            noDistributionRulesFound: "No distribution rules found.",
            noDeliveryRulesFound: "No delivery rules found.",
            cutOffTimesChanges: "Changes have been made to the cut off times. Press 'save' to make the changes final.",
            noWebhookFailedFound: "No failed webhooks found.",
            noWebhookSubscriptionsFound: "No subscriptions found."
        },
        pending: {
            retryWebhook: "Resend webhook..."
        },
        success: {
            cancelledWebhook: "The webhook has been canceled.",
            webhookResent: "The webhook has been resent.",
            savedChanges: "Your changes are saved.",
            algorithmSubmit: "The order circulation algorithm has been updated succesfully.",
            configurationSubmit: "the order circulation configuration has been updated succesfully.",
            importRowsFound: (count = 0) => `${count} ${count === 1 ? "row" : "rows"} imported.`
        },
        validation: {
            invalidOrganization: "The organization you specified is not part of the Omnichannel Integration Suite.",
            invalidValue: "The value you entered is invalid.",
            requiredValue: "This field is required.",
            numberMinimumOne: "The value must be at least 1.",
            numberMinimumZero: "The value must be at least 0.",
            numberMaxSeven: "The value must be at most 7.",
            onlyWholeNumbers: "The value can only be an integer.",
            priorityNumberMinimumOne: "Priority: The value must be at least 1",
            priorityOnlyWholeNumbers: "Priority: The value can only be an integer.",
            maxOutstandingRequestsNumberMinimumOne: "Max. open requests: The value must be at least 1",
            maxOutstandingRequestsOnlyWholeNumbers: "Max. open requests: The value can only be an integer.",
            codeIsRequired: "The field 'code' is required.",
            columnError: (column) => `Table header '${column}' contains typos or is not allowed.`,
            isWarehouseBoolean: `Warehouse: The value must be true or false.`,
            isShippingBoolean: "Shipping location: The value must be true or false.",
            isExternalBoolean: "External stock location: The value must be true or false.",
            branchCodeString: "Code: The value cannot be empty.",
            autoAcceptBoolean: "Auto accept: The value must be true or false.",
            maxOutstandingRequestsNumber: "Max. open requests: The value must be a number.",
            activeBoolean: "Active: The value must be true or false.",
            tooFewFields: (row) => `The number of table headers does not match the number of cells (rule ${row}).`,
            tooManyFields: (row) => `The number of cells does not match the number of table headers (rule ${row}).`
        },
        loading: {
            default: "Loading..."
        }
    }
};

export default enGb;
