const frFr = {
    global: {
        active: "Actif",
        autoAccept: "Accepter automatiquement",
        back: "Précédent",
        branch: "Store",
        cancel: "Annuler",
        change: "Modifier",
        changes: "Changements",
        changeLanguage: "Changer de langue",
        changeOrganization: "Changer d'organisation",
        chooseFile: "Choisir le fichier",
        close: "Fermer",
        currentOrganization: "Organisation Actuelle",
        enterOrganization: "Entrez votre organisation",
        login: "Connexion",
        logout: "Se déconnecter",
        ois: "Omnichannel Integration Suite",
        oisAbbr: "OIS",
        priority: "Priorité",
        save: "sauvegarder",
        specifyOrganization: "Spécifiez l'organisation",
        configurationAlgorithm: "Configuration & Algorithme",
        configuration: "Configuration",
        algorithm: "Algorithme",
        delivery: "Livraison",
        distribution: "Distribution",
        tenant: "Tenant",
        language: "Langue",
        warehouse: "Magasin",
        shippingLocation: "Lieu d'expédition",
        externalStockLocation: "Emplacement de stockage externe",
        maxOpenRequests: "Max. demandes ouvertes",
        cutOffTime: "Temps limite",
        setup: "Régler",
        monday: "Lundi",
        tuesday: "Mardi",
        wednesday: "Mercredi",
        thursday: "Jeudi",
        friday: "Vendredi",
        saturday: "Samedi",
        sunday: "Dimanche",
        modules: "Modules",
        noFileSelected: "Aucun fichier sélectionné",
        toDashboard: "Vers le tableau de bord",
        toLogin: "Ouvrir une session",
        search: "Chercher",
        downloadCsvExample: "Télécharger l'exemple (.csv)",
        import: "Importer",
        importedData: (count = 0) => `${count} ${count === 1 ? "ligne importée" : "lignes importées"}`,
        noChanges: "Il n'y a actuellement aucun changement",
        empty: "vide",
        undoChanges: "Tout défaire",
        or: "ou",
        navigateToType: "Naviguer par type",
        yes: "Oui",
        no: "Non",
        moreInformation: "Plus d'information",
        noResultsFound: "Aucun résultat trouvé",
        articleSearchInfo: "Recherchez l'un des attributs suivants : id, articleNumber, name, posDescription ou vendorArticleNumber",
        copied: "Copié!",
        copyToClipboard: "Copier dans le presse-papier",
        modifiedOn: "Modifié le",
        createdOn: "Fait sur",
        stockLocationType: "Type d'emplacement de stockage",
        shippingLocationInternal: "Expédition depuis l'emplacement de l'entrepôt",
        backToOverview: "Retour à l'aperçu",
        general: "Général"
    },
    form: {
        label: {
            routesActive: "Routes actives",
            maxCirculationTimeframeInDays: "Temps de circulation maximum en jours",
            excludeWarehouseExpiredCirculations: "Exclure l'entrepôt du temps de circulation maximum",
            centralizedDelivery: "Livraison centralisée",
            daysToExcludeExternalBranchFromCirculation: "Jours pour exclure les emplacements d'inventaire externes",
            hoursToExcludeBranchForPickRequestAfterReject: "Refuser la demande en heures",
            hoursToExcludeWarehouseForPickRequestAfterReject: "Demande de refus en heures pour l'entrepôt",
            filterInputByWebshopCode: "Filtrer les demandes par code de boutique en ligne",
            pickRequestExpirationTimeInHours: "Temps d'expiration de la demande en heures",
            yes: "Oui",
            no: "Non",
            first: "Première",
            afterWhich: "Par la suite",
            inCaseComplete: "si complet",
            everythingFrom: "tout éteint",
            inCountryOf: "dans le pays de",
            branchType: "Type de lieu",
            openRequests: "Demandes en attente",
            priority: "Priorité",
            priorityByStockLocations: "La priorité 1 dans les emplacements de stock sera choisie en premier",
            requestsPastDays: "Les demandes durent x jours",
            stock: "Stocker",
            most: "Plus",
            least: "Moins",
            selectAnOption: "Sélectionner une option",
            newRule: "Nouvelle règle",
            searchByBranch: "Rechercher par lieu...",
            searchBySetting: "Rechercher par paramètre..."
        },
        info: {
            routesActive: "Détermine si les routes sont actives. Les tournées sont gérées via les emplacements de stockage.",
            maxCirculationTimeframeInDays:
                "Détermine le nombre maximum de jours pendant lesquels la commande peut être distribuée avant d'être annulé.",
            excludeWarehouseExpiredCirculations:
                "Les demandes de circulation ouverte qui sont ouvertes pour l'entrepôt pendant plus de 24 heures peuvent ou non être automatiquement rejetées.",
            centralizedDelivery: "Determines whether there is central delivery.",
            daysToExcludeExternalBranchFromCirculation:
                "Détermine le nombre de jours (y compris aujourd'hui) pendant lesquels les emplacements de stock externes sont exclus de la circulation.",
            stockLocationPriority: "Déterminez la priorité, 1 étant la priorité la plus élevée.",
            hoursToExcludeBranchForPickRequestAfterReject:
                "Le nombre d'heures pendant lesquelles une demande n'est pas renvoyée à un emplacement après que cet emplacement a précédemment rejeté la demande.",
            hoursToExcludeWarehouseForPickRequestAfterReject:
                "Le nombre d'heures pendant lesquelles une demande ne retourne pas à l'entrepôt après que l'entrepôt a précédemment rejeté la demande.",
            filterInputByWebshopCode:
                "Indique si l'entrée de circulation doit être filtrée en fonction le code de la boutique en ligne de la commande.",
            pickRequestExpirationTimeInHours: "Le nombre d'heures avant qu'une demande sur le emplacement actuelle ne soit acheminée et transmise à la branche suivante.",
            cutOffTimeInternalShippingAgentAvailability: "Le propre transport n'est disponible que pour la livraison centrale.",
            cutOffTimeRemainingShippingAgentAvailability: "Ce transporteur est disponible uniquement dans les points d'expédition."
        }
    },
    components: {
        stockLocationsImport: {
            title: "store",
            name: "code",
            isWarehouse: "magasin",
            isShipping: "lieu d'expédition",
            isExternal: "emplacement de stockage externe",
            autoAccept: "accepter automatiquement",
            maxOutstandingRequests: "max. demandes ouvertes",
            priority: "priorité",
            active: "actif"
        }
    },
    country: {},
    language: {
        nlNL: "Nederlands",
        enGB: "English",
        frFR: "Français",
        deDE: "Deutsch"
    },
    pages: {
        page404: {
            text: "404",
            description: ""
        },
        organizationRedirect: {
            text: "Redirection d'organisation",
            description: ""
        },
        login: {
            text: "Connexion",
            description: ""
        },
        root: {
            text: "Racine",
            description: ""
        },
        homepage: {
            text: "Page d'accueil",
            description: ""
        },
        dashboardRoot: {
            text: "Tableau de bord",
            description: ""
        },
        dashboard: {
            text: "Tableau de bord",
            description: "Configurez OIS en cliquant sur le module souhaité."
        },
        stockRoot: {
            text: "Stocker",
            description: "Organisez la gestion des stocks ici."
        },
        stock: {
            text: "Stocker",
            description: "Organisez la gestion des stocks ici."
        },
        stockManagement: {
            text: "Gestion de stock",
            description: "Organisez la gestion des stocks ici."
        },
        orderCirculationRoot: {
            text: "Circulation des commandes",
            description: "Définissez ici les paramètres de circulation des commandes."
        },
        orderCirculation: {
            text: "Circulation des commandes",
            description: "Définissez ici les paramètres de circulation des commandes."
        },
        configurationAlgorithm: {
            text: "Configuration & algorithme",
            description: "Contrôlez la configuration et l'algorithme."
        },
        stockLocations: {
            text: "Emplacements de stockage",
            description: "Gérer les emplacements de stockage."
        },
        stockLocationsImport: {
            text: "Importer",
            description: "Appliquez les modifications aux emplacements existants via une importation CSV."
        },
        myAccount: {
            text: "Mon compte",
            description: "Afficher les détails de votre compte."
        },
        changeTenant: {
            text: "Changer de locataire",
            description: "Modifiez le locataire pour lequel vous souhaitez configurer OIS."
        },
        changeLanguage: {
            text: "Changer de langue",
            description: "Modifiez la langue du tableau de bord de configuration OIS."
        },
        settings: {
            text: "Réglages",
            description: "Modifiez les paramètres généraux d'OIS."
        },
        dataViewerRoot: {
            text: "Data viewer",
            description: "Afficher diverses informations sur les produits et les prix associés."
        },
        dataViewer: {
            text: "Data viewer",
            description: "Recherchez dans la gamme de produits, consultez les informations sur les produits et les prix associés."
        },
        articlesRoot: {
            text: "Articles",
            description: "Recherchez des produits et affichez les informations et les prix sur les produits."
        },
        articles: {
            text: "Articles",
            description: "Recherchez des produits et affichez les informations et les prix sur les produits."
        },
        articleDetailRoot: {
            text: "Général",
            description: "Général"
        },
        articleDetail: {
            text: "Détails de l'article",
            description: "Général."
        },
        variants: {
            text: "Variantes",
            description: "Variantes."
        },
        prices: {
            text: "Prix",
            description: "Prix."
        },
        properties: {
            text: "Propriétés",
            description: "Propriétés."
        },
        monitoringRoot: {
            text: "Surveillance",
            description: "Afficher les webhooks ayant échoué et enregistrés."
        },
        monitoring: {
            text: "Surveillance",
            description: "Obtenez un aperçu de vos webhooks ayant échoué et enregistrés."
        },
        webhooksRoot: {
            text: "Webhooks",
            description: "Afficher les webhooks ayant échoué et enregistrés."
        },
        webhooks: {
            text: "Webhooks",
            description: "Afficher les webhooks ayant échoué et enregistrés."
        },
        webhooksFailed: {
            text: "Échec",
            description: ""
        },
        webhooksSubscriptions: {
            text: "Abonnements",
            description: ""
        }
    },
    contextMenu: {
        retryWebhook: "Renvoyer",
        showPayload: "Afficher la charge utile",
        showResponseMessage: "Afficher le message de réponse"
    },
    messages: {
        confirmation: {
            cancelWebhook: "Voulez-vous annuler ce webhook?",
            retryWebook: "Souhaitez-vous renvoyer ce webhook?"
        },
        error: {
            branchCutOffTimesFetch: "Une erreur s'est produite lors de la récupération des valeurs d'heure limite de la branche.",
            catalogFetch: "Une erreur s'est produite lors de la récupération des valeurs du catalogue.",
            priceFetch: "Une erreur s'est produite lors de la récupération des prix.",
            configurationValuesFetch: "Une erreur s'est produite lors de l'obtention des valeurs de configuration.",
            distributionRulesFetch: "Une erreur s'est produite lors de la récupération des règles de distribution.",
            distributionTypesFetch: "Une erreur s'est produite lors de la récupération des types de distribution.",
            deliveryRulesFetch: "Quelque chose s'est mal passé lors de la récupération des lignes maritimes.",
            countryTypesFetch: "Une erreur s'est produite lors de la récupération des types de pays.",
            branchesFetch: "Une erreur s'est produite lors de la récupération des magasins.",
            branchTypesFetch: "Une erreur s'est produite lors de la récupération des types d'emplacement.",
            branchSettingsFetch: "Une erreur s'est produite lors de la récupération des paramètres de la branche.",
            shippingAgentsFetch: "Quelque chose s'est mal passé lors de la prise en charge des transitaires.",
            algorithmSubmit: "Une erreur s'est produite lors de la sauvegarde de l'algorithme de circulation des commandes.",
            configurationSubmit: "Une erreur s'est produite lors de l'enregistrement de la configuration de la circulation des commandes.",
            oisSettingGroupsFetch: "Une erreur s'est produite lors de la récupération des groupes de paramètres OIS.",
            oisSettingsFetch: "Une erreur s'est produite lors de la récupération des paramètres OIS par groupe.",
            oisSettingsSubmit: "Une erreur s'est produite lors de l'enregistrement des paramètres OIS.",
            defaultError: "Quelque chose s'est mal passé."
        },
        warning: {},
        info: {
            noAccessToPageTitle: "Pas d'accès",
            noAccessToPageDescription:
                "Vous ne pouvez pas accéder à cette page. Cliquez sur le bouton ci-dessous pour revenir au tableau de bord.",
            pageNotFoundTitle: "Page non trouvée.",
            pageNotFoundDescriptionDashboard:
                "La page à laquelle vous tentez d'accéder est introuvable. Cliquez sur le bouton ci-dessous pour revenir au tableau de bord.",
            pageNotFoundDescriptionLogin:
                "La page à laquelle vous tentez d'accéder est introuvable. Cliquez sur le bouton ci-dessous pour revenir à la page de connexion.",
            noDistributionRulesFound: "Aucune règle de distribution trouvée.",
            noDeliveryRulesFound: "Aucune ligne de livraison trouvée.",
            cutOffTimesChanges:
                "Des modifications ont été apportées aux heures limites. Appuyez sur 'enregistrer' pour valider définitivement les modifications.",
            noWebhookFailedFound: "Aucun webhook ayant échoué trouvé.",
            noWebhookSubscriptionsFound: "Aucune inscription trouvée."
        },
        pending: {
            retryWebhook: "Renvoyer..."
        },
        success: {
            cancelledWebhook: "Le webhook a été annulé.",
            webhookResent: "Le webhook a été renvoyé.",
            savedChanges: "Les modifications sont enregistrées.",
            algorithmSubmit: "L'algorithme de circulation des commandes a été enregistré avec succès.",
            configurationSubmit: "La configuration de la circulation de la commande a été enregistrée avec succès.",
            importRowsFound: (count = 0) => `${count} ${count === 1 ? "ligne importée" : "lignes importées"}.`
        },
        validation: {
            invalidOrganization: "L'organisation que vous avez spécifiée ne fait pas partie d'Omnichannel Integration Suite.",
            invalidValue: "La valeur saisie n'est pas valide.",
            requiredValue: "Ce champ est requis.",
            numberMinimumOne: "La valeur doit être au moins égale à 1.",
            numberMinimumZero: "La valeur doit être au moins égale à 0.",
            numberMaxSeven: "La valeur doit être inférieure ou égale à 7.",
            onlyWholeNumbers: "La valeur ne peut être qu'un nombre entier.",
            priorityNumberMinimumOne: "Priorité: La valeur doit être au moins égale à 1.",
            priorityOnlyWholeNumbers: "Priorité: La valeur ne peut être qu'un nombre entier.",
            maxOutstandingRequestsNumberMinimumOne: "Max. demandes ouvertes: La valeur doit être au moins égale à 1.",
            maxOutstandingRequestsOnlyWholeNumbers: "Max. demandes ouvertes: La valeur ne peut être qu'un nombre entier.",
            codeIsRequired: "Le champ 'code' est obligatoire.",
            columnError: (column) => `En-tête de tableau '${column}' contient des fautes d'orthographe ou n'est pas autorisée.`,
            isWarehouseBoolean: `Magasin: La valeur doit être 'true' ou 'false'.`,
            isShippingBoolean: "Lieu d'expédition: La valeur doit être 'true' ou 'false'.",
            isExternalBoolean: "Emplacement de stockage externe: La valeur doit être 'true' ou 'false'.",
            branchCodeString: "Code: La valeur ne peut pas être vide",
            autoAcceptBoolean: "Accepter automatiquement: La valeur doit être 'true' ou 'false'.",
            maxOutstandingRequestsNumber: "Max. demandes ouvertes: La valeur doit être un nombre.",
            activeBoolean: "Actif: La valeur doit être 'true' ou 'false'.",
            tooFewFields: (row) => `Le nombre d'en-têtes de tableau ne correspond pas au nombre de cellules (règle ${row}).`,
            tooManyFields: (row) => `Le nombre de cellules ne correspond pas au nombre d'en-têtes de tableau (règle ${row}).`
        },
        loading: {
            default: "Chargement..."
        }
    }
};

export default frFr;
