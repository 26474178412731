import { useState } from "react";
import { useRecoilValue } from "recoil";
import generalStorageKeysAtom from "store/generalStorageKeys";
import translationAtom from "store/translation";
import { organizationsMap } from "@divide/ois-react-framework/constants";
import { cookie } from "@divide/ois-react-framework/common";
import { getMainDomain, setOrganizationNameToCookies } from "@divide/ois-react-framework/helpers";
import { TextField } from "components/FormElements";
import Button from "components/Button";
import { useLogin } from "./../";
import styles from "./OrganizationSelect.module.css";

export function OrganizationSelect () {
    const translation = useRecoilValue(translationAtom);
    const generalStorageKeys = useRecoilValue(generalStorageKeysAtom);
    const { setOrganizationSelectActive, organization } = useLogin();
    const [organizationInput, setOrganizationInput] = useState("");
    const [validation, setValidation] = useState();

    const handleSaveOrganization = (e) => {
        e.preventDefault();
        
        if (organizationsMap.has(organizationInput?.toLowerCase())) {
            cookie.remove(generalStorageKeys.tenant, getMainDomain(), "/");
            setOrganizationNameToCookies(organizationInput.toLowerCase(), generalStorageKeys.organization);
            window.location.reload();
        } else {
            setValidation(translation("messages.validation.invalidOrganization"));
        }
    }

    return (
        <>
            <header className={styles["header"]}>
                <h1 className={styles["title"]}>
                    {organization?.length > 0 ? (
                        <>{translation("global.changeOrganization")}</>
                    ) : (
                        <>{translation("global.enterOrganization")}</>
                    )}
                </h1>
                <p className={styles["subtitle"]}>{translation("global.enterOrganization")}</p>
            </header>
            
            <form className={styles["organization"]} onSubmit={handleSaveOrganization}>
                <TextField autoFocus as="text" className={styles["field"]} value={organizationInput} onInput={(e) => {
                    setOrganizationInput(e.currentTarget.value);
                    setValidation();
                }} />

                {validation?.length > 0 && (
                    <div className={styles["validation"]}>{validation}</div>
                )}

                <div className={styles["actions"]}>
                    <Button type="submit" fullWidth>{translation("global.save")}</Button>

                    {organization?.length > 0 && (
                        <span className={styles["back-button"]} onClick={() => setOrganizationSelectActive(false)}>{translation("global.back")}</span>
                    )}
                </div>
            </form>
        </>
    );
}